.ith {
    display: flex;

    &--vertical {
        flex-direction: column;
        height: 100%;

        &__navigation-arrow {
            text-align: center;
            width: 100%;
            padding: 10px 0;
        }
    }

    &--horizontal {
        flex-direction: row;
        width: 100%;
        min-height: max(15%, 100px);
    }

    &__navigation-arrow {
        align-self: center;
        font-size: 24px;
        color: white;
        cursor: pointer;
    }

    img {
        width: calc(100% - 10px);
    }

    .disabled {
        color: gray;
        cursor: default;
    }

    .swiper {
        width: 100%;

        &__vertical {
            .swiper-wrapper {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
            }
        }

        &__horizontal {
            display: flex;
            align-items: center;
        }

        &-slide {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &-autoheight {
            display: flex;
            flex-direction: column;
            flex: 1;
        }
    }
}

.empty {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &__text {
        text-align: center;
        color: var(--font-light);
    }
}
