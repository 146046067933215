.cws {
    display: flex;
    height: 50%;
    flex: 1 1 100%;

    &__content {
        background: var(--nav);
        padding: 10px;
        flex: 1 1 100%;
        padding: 0 10px 10px 10px;
        overflow-x: hidden;
        overflow-y: auto;
    }

    &__sidebar {
        display: flex;
        flex-direction: column;
        background: var(--nav);
        color: var(--font-light);
        padding: 10px;
        flex: 1 0 max(200px, 11vw);
        width: max(200px, 11vw);
        gap: 10px;
    }

    &__modal {
        display: flex;
        position: absolute;
        top: 72px;
        bottom: 0;
        right: 0;
        margin-top: auto;
        margin-bottom: auto;
        z-index: 3;
        pointer-events: none;

        &--active {
            pointer-events: all;
        }

        &__icon {
            height: 1.2em;
            font-size: 1.5em;
            padding: 14px;
            border-radius: 10px 0 0 10px;
            color: var(--font-light);
            pointer-events: all;
            cursor: pointer;
        }
    }

    .modal-bg {
        background: var(--darker);
    }
}

.cws--column {
    display: flex;
    flex-direction: column;
    padding-top: 0;
    padding-right: 0;
}

.cws--row {
    display: flex;
    flex: 1;
    flex-direction: row;
    width: auto;
}

.cws__sidebar.cws--row {
    padding-bottom: 20px;
    padding-right: 69px;
}
