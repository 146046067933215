.input-button {
    height: 2.5rem;
    color: var(--font-light);
    background: var(--primary);
    font-weight: bold;
    border: none;
    cursor: pointer;

    &:disabled {
        opacity: 0.4;
        cursor: default;
    }
}
