.video-player {
    display: grid;
    grid-template-rows: minmax(0, 1fr) 15px 25px 50px;
    grid-template-columns: auto;
    height: 100%;
    overflow: hidden;

    video {
        width: 100% !important;
        height: 100% !important;
        align-self: center;
    }

    &__progress-bar {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: #363738;
        width: 100%;
        margin: 0;
        overflow: hidden;
        cursor: pointer;

        &:focus-visible {
            outline: none;
        }

        &::-webkit-slider-thumb {
            appearance: none;
            box-shadow: -2000px 0 0 2000px #737474;
            width: 10px;
        }
    }

    &__separator {
        background: #20222a;
        border-bottom: 2px solid var(--nav);
    }

    &__controls {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #20222a;

        &__left,
        &__mid,
        &__right {
            display: flex;
            align-items: center;
            text-align: center;
            height: 100%;
        }

        .time--current {
            color: var(--font-light);
        }

        .time--duration {
            color: grey;
        }
    }

    button,
    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 56px;
        height: 100%;
        cursor: pointer;
        color: white;
        background: transparent;
        outline: none;
        border: none;
        text-align: center;
    }

    button.bi {
        font-size: 28px;
        color: white;

        &--small {
            font-size: 24px;
        }

        &--smaller {
            font-size: 16px;
        }

        &--off {
            color: gray;
        }
    }

    select {
        font-size: 16px;

        option {
            background: #20222a;
            cursor: pointer;
        }
    }
}
